<template>
    <div class="shopping-all m-scroll">
        <HeaderBar title="Cancel order" />
        <div class="shopping-main">
            <div class="store-item m-padding" v-for="(item, index) in storeList" :key="index">
                <div class="store-title van-hairline--bottom" >
                    Select product
                </div>
                <div class="goods-list">
                    <div v-for="(it, ind) in item.goodsList" :key="ind" right-width="145px">
                        <div class="goods-item">
                            <img src="@/assets/fork-imgs/156740814737337x3vk.png" alt="" class="goods-img">
                            <div class="goods-right">
                                <p class="van-multi-ellipsis--l2">Xiaomi Mini 2 Smart Body Scale Balance Fat Weight Scales</p>
                                <div class="sku" @click.stop="handleCartNoAnimat">
                                    black ｜ size
                                </div>
                                <div class="total" @click.stop="">
                                    <span>$168.79</span>
                                    ×{{it.num}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 显示理由 -->
        <div class="isViewDetail" :class="{'viewDetail' : isViewDetail}" @click="isViewDetail = !isViewDetail">
            <span>Reason for Cancellation (Required)</span>
            <span>
                <img src="@/assets/payment/down.png" alt="">
            </span>
        </div>
        <!-- 选择理由 -->
        <div class="pay-box" v-show="isViewDetail">
            <div class="pay-item" v-for="(item, index) in reasonList" :key="index" :class="{'pay-active' : reasonActive == index}"
            @click="reasonActive = index">
                <span>{{item}}</span>
                <img src="@/assets/payment/select.png" alt="">
                <img src="@/assets/payment/active.png" alt="">
            </div>
        </div>
        <div class="apply-set">Confirm Cancel</div>
    </div>
</template>

<script>
import HeaderBar from '@/components/en/m-header.vue'
export default {
    components: { HeaderBar },
    data() {
        return {
            value: '1',
            storeList: [
                {
                    x: '',
                    goodsList: [
                        {name: ''},
                        {name: ''}
                    ]
                }
            ],
            isViewDetail: false,
            reasonList: ['拍错了', '不想买了', '没钱了', '地址填错了', '别的平台价格更低', '其他'],
            reasonActive: 0
        }
    },
    methods: {
        
    },
    created() {

    }
}
</script>

<style scoped lang="less">
@import './cancle.less';
</style>

<style lang="less">
.shopping-all{
    .m-cat-padding{padding:0}
}
</style>
